@media (min-width: 992px) {
  .container {
    max-width: 1110px;
  }
}

.navbar-dark{
  background-color: var(--primary);

  .logo path{
    fill: var(--dark);
  }
}

.navbar{
  .nav-link {
    color: var(--dark);
  }
}

.navbar-toggler .material-icons {
  color: var(--dark);
}

//HERO

.hero{
  background: url("../../img/contour-bg.svg") no-repeat center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: var(--primary);
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-bottom: 6rem;
  padding-top: 3em;

  .display-xl{
    font-size: 2.5rem !important;
    font-weight: 800;
  }

  @media (max-width: 767px){
    margin-bottom: 6rem;
    padding-top: 3rem;

    .display-xl{
      font-size: 3rem !important;
      padding: 0 !important;
      text-align: center;
    }
  }

  .search-launcher{
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 50px;
    padding: 1.2em 2em;
    transition: background-color 0.3s;

    &:hover{
      background-color: rgba(0, 0, 0, 0.72);
    }
  }

  .hero-img{
    img{
      height: auto;
      width: auto;

      @media (max-width: 768px){
        padding: 0em 1em 0em;
      }
    }

    a{
      position: absolute;
      bottom: -30px;
      right: 1em;
      left: 1em;
      margin: auto;
      max-width: 210px;
    }

  }
}

// COMPARISON

.app-comparison{
  .nav-item{
    .button{
      font-size: 1.25em !important;
    }

    .nav-link{
      background: var(--gray-dark);
    }

    .os-button.active{
      background-color: #FF1F75 !important;
      color: #fff;
    }

    .alltrails-button.active{
      background-color: #64F67B !important;
    }
  }

  .tab-pane{
    border: 1px solid #434343;
    background: var(--gray-dark);
    padding: 3em 3em 0;

    @media (max-width: 768px){
      padding: 1em 1em 0;
    }
  }
}

// MAP INTRO

.map-intro{
  img{
    display: block;
    margin: -60px auto 0 auto;
    z-index: -1;

    @media (max-width: 1024px){
      margin-top: -100px;
    }

    @media (max-width: 768px){
      width: 908px;
      max-width: none;
      margin-top: -50px;
    }
  }

  @media (max-width: 1024px){
   overflow: hidden;
  }

}

// STATS CONTAINER

.stats-container{

  @media (max-width: 1024px){
    .display-3{
      font-size: 3rem !important;
    }

    .display-4{
      font-size: 1.2rem !important;
    }
  }

  @media (max-width: 767px){
    .display-3{
      font-size: 2.5rem !important;
    }

    .display-4{
      font-size: 1rem !important;
    }
  }

  @media (max-width: 767px){
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

}

//FEATURE CAROUSEL

.feature-carousel {
  padding: 3em;

  .material-icons {
    font-size: 70px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.down {
      rotate: 180deg;
      color: var(--primary);
    }
  }

  @media (max-width: 1024px){
    padding: 3em 2em;
  }
}

//QUOTES

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .testimonial-text{
    position: absolute;
    bottom: 2em;
    text-align: center;
    padding: 1em;
  }

  @media (max-width: 1024px){
    h4{
      font-size: 1em;
    }
  }

  @media (max-width: 767px){
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

.testimonial-1 {
  grid-area: 1 / 1 / 3 / 2;
  padding: 1em;
  @media (max-width: 767px){
    grid-area: 1 / 1 / 3 / 3;
  }
}
.testimonial-2 {
  grid-area: 1 / 2 / 2 / 3;
  padding: 1em;
  @media (max-width: 767px){
    grid-area: 3 / 1 / 4 / 3;
  }
}
.testimonial-3 {
  grid-area: 2 / 2 / 3 / 3;
  padding: 1em;
  @media (max-width: 767px){
    grid-area: 4 / 1 / 5 / 3;
  }
}

.modal-dialog {
  justify-content: start !important;
}

